var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"titles"},[_vm._v("Message")]),_c('v-card',{staticClass:"pb-4",style:(_vm.cardStyle),attrs:{"elevation":"1"}},[_c('v-btn',{staticStyle:{"top":"-50px","margin-right":"-1%"},attrs:{"small":"","absolute":"","top":"","right":""},on:{"click":_vm.gotoMail}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-backburger")]),_vm._v("Back ")],1),_c('v-card-text',{staticClass:"pb-0"},[_c('div',[_c('span',{staticClass:"overline"},[_vm._v("Header Image")]),_c('br'),_c('image-upload',{attrs:{"image_path":typeof _vm.email.header_image === 'string'?_vm.email.header_image:null,"height":240},on:{"upload":function (data) {
                  _vm.email.header_image = data;
                },"remove":function () {
                  _vm.email.header_image = null;
                }}})],1),_c('br'),_c('v-row',[_c('div',{staticClass:"ml-4",staticStyle:{"margin-top":"10px"}},[_c('span',{staticClass:"overline"},[_vm._v("Placeholders")]),_c('v-tooltip',{attrs:{"top":"","max-width":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-information")])],1)]}}])},[_c('span',{staticClass:"text-center"},[_vm._v(" Placeholder is used to add dynamic data in you message. Placeholder will be replaced with actual data representing each of them. Please click to copy and paste it on your message ")])]),_vm._l((_vm.placeholders),function(item){return [_c('v-chip',{key:item.id,staticClass:"mr-2 pa-4",on:{"click":function($event){return _vm.copyPlaceholder(item)}}},[_vm._v(" "+_vm._s(item.name)+" "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("mdi-content-copy")])],1)]})],2),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('text-editor',{attrs:{"message":_vm.emailEditContent},on:{"complete":_vm.setEmailContent}})],1)],1),_c('br'),_c('span',{staticClass:"overline"},[_vm._v("Footer Image")]),_c('v-row',_vm._l((3),function(num,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('div',[_c('image-upload',{attrs:{"image_path":_vm.email.footer_images[index],"height":240},on:{"upload":function (data) {
                  _vm.email.footer_images[index] = data;
                },"remove":function () {
                  _vm.email.footer_images[index] = null;
                }}})],1)])}),1),_c('v-row',[_c('div',{staticClass:"ml-4",staticStyle:{"margin-top":"10px"}},[_c('span',{staticClass:"overline"},[_vm._v("Policy")])]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('text-editor',{attrs:{"message":_vm.email.policy},on:{"complete":_vm.setPolicyContent}})],1)],1)],1)],1),_c('v-row',{staticClass:"mt-3"},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2 white--text blue-color",attrs:{"text":""},on:{"click":_vm.gotoMail}},[_vm._v("Close")]),_c('v-btn',{staticClass:"ma-2 white--text teal-color",attrs:{"color":"darken-1","text":""},on:{"click":_vm.saveMarketingMessage}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }