<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="titles">Message</div>
      <v-card :style="cardStyle" class="pb-4" elevation="1">
        <v-btn
          small
          @click="gotoMail"
          absolute
          top
          right
          style="top: -50px; margin-right: -1%"
        >
          <v-icon small>mdi-backburger</v-icon>Back
        </v-btn>
        <v-card-text class="pb-0">
          <div>
            <span class="overline">Header Image</span>
            <br>
            <image-upload
                @upload="
                  (data) => {
                    email.header_image = data;
                  }
                "
                @remove="
                  () => {
                    email.header_image = null;
                  }
                "
                :image_path="typeof email.header_image === 'string'?email.header_image:null"
                :height="240"
            />
          </div>
          <br>
          <v-row>
            <div class="ml-4" style="margin-top: 10px">
              <span class="overline">Placeholders</span>
              <v-tooltip top max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon color="grey lighten-1">mdi-information</v-icon>
                  </v-btn>
                </template>
                <span class="text-center">
                  Placeholder is used to add dynamic data in you message.
                  Placeholder will be replaced with actual data representing
                  each of them. Please click to copy and paste it on your
                  message
                </span>
              </v-tooltip>
              <template v-for="item in placeholders">
                <v-chip
                  :key="item.id"
                  class="mr-2 pa-4"
                  @click="copyPlaceholder(item)"
                >
                  {{ item.name }}
                  <v-icon right small>mdi-content-copy</v-icon>
                </v-chip>
              </template>
            </div>
            <v-col cols="12" sm="12" md="12">
              <text-editor
                @complete="setEmailContent"
                :message="emailEditContent"
              />
            </v-col>
          </v-row>
          <br>
          <span class="overline">Footer Image</span>
          <v-row>
            <v-col cols="4" v-for="(num,index) in 3" :key="index">
              <div>
                <image-upload
                    @upload="
                  (data) => {
                    email.footer_images[index] = data;
                  }
                "
                    @remove="
                  () => {
                    email.footer_images[index] = null;
                  }
                "
                    :image_path="email.footer_images[index]"
                    :height="240"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <div class="ml-4" style="margin-top: 10px">
              <span class="overline">Policy</span>
            </div>
            <v-col cols="12" sm="12" md="12">
              <text-editor
                  @complete="setPolicyContent"
                  :message="email.policy"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card
      ><v-row class="mt-3">
        <v-spacer></v-spacer>
        <v-btn @click="gotoMail" class="ma-2 white--text blue-color" text
          >Close</v-btn
        >
        <v-btn
          color="darken-1"
          class="ma-2 white--text teal-color"
          text
          @click="saveMarketingMessage"
          >Save</v-btn
        >
      </v-row>

    </v-form>
  </v-container>
</template>
<script>
import moment from "moment";
import TextEditor from "@/components/Marketing/TextEditor";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      messageForm: {
        message: "",
      },
      menu: false,
      selected: [],
      currentPageIds: [],
      valid: false,
      rules: [],
      placeholders: [],
      type: "order_reservation",
      emailEditContent: ``,
      currentDay: moment().format("YYYY-MM-DD"),
      email:{
        header_image:null,
        footer_images:[],
        policy:""
      }
    };
  },
  components: {
    TextEditor,
  },
  mounted() {
    this.getPlaceholders();
    this.getMessageDetails();
  },
  computed: {
    ...mapGetters({
      checkReadPermission: "checkReadPermission",
    }),
  },
  methods: {
    copyPlaceholder(data) {
      const el = document.createElement("textarea");
      el.value = data.placeholder;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.showInfo(
        "Placeholder for " + data.name + " copied to clipboard",
        2000
      );
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    getPlaceholders() {
      this.$http
        .get("venues/marketing/messages/placeholders?type=" + this.type)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.placeholders = data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    saveMarketingMessage() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      this.showLoader();
      const formData = new FormData();
      formData.append('message',this.messageForm.message)
      formData.append('header_image',this.email.header_image)
      formData.append('policy',this.email.policy)
      if (this.email.footer_images){
        this.email.footer_images.forEach((image, index) => {
          formData.append(`footer_images[${index}]`, image)
        })
      }
      const config = {
        method: "post",
        url: "venues/marketing/template/order-reservation-mail",
        data: formData,
      };
      this.$http(config)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.showSuccess("Added successfully");
            this.$router.push({
              name: "Marketing",
            });
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    getMessageDetails() {
      this.showLoader("Loading");
      this.$http
        .get("venues/marketing/template/order-reservation-mail")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;

            if (data.email){
              this.messageForm.message = data.email.content;
              this.emailEditContent = data.email.content;
              this.email = data.email
            }
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
    setEmailContent(content) {
      this.messageForm.message = content;
    },
    setPolicyContent(content) {
      this.email.policy = content;
    },
    gotoMail() {
      this.$router.push({
        name: "Marketing",
      });
    },
  },
};
</script>

<style></style>
